

















import { Prop, Component, Mixins } from 'vue-property-decorator'

import { ModalType } from '~/store/modal'
import { ModalFunctions } from '~/mixins/modals'

@Component
export default class AccountRequired extends Mixins(ModalFunctions) {
    @Prop() modalType: ModalType

    get iconToShow() {
        if (this.modalType === ModalType.RequireAccountForLike) return 'heart-o'
        else if (this.modalType === ModalType.RequireAccountForFollow) return 'microphone-o'
    }
    get message() {
        if (this.modalType === ModalType.RequireAccountForLike) return 'like this beat'
        else if (this.modalType === ModalType.RequireAccountForFollow) return 'follow producers'
    }

    login() {
        this.closeModal()
        this.$router.push({ name: 'Login' })
    }
    signup() {
        this.closeModal()
        this.$router.push({ name: 'Signup' })
    }
}
