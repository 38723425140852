var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal__container modal__container--overlay" },
    [
      _c("div", { staticClass: "modalDialog modalDialog--noPadding" }, [
        _c("div", { staticClass: "modalContent modalContent--column" }, [
          _c(
            "div",
            { staticClass: "modalContent__top" },
            [
              _c("icon", {
                staticClass: "modalcloseButton",
                attrs: { name: "close" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              }),
              _c("h3", { staticClass: "modal__title" }, [
                _vm._v("Signup for TheCharts"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modalContent__body" },
            [
              _c("icon", {
                staticClass: "modal__icon",
                attrs: { name: _vm.iconToShow },
              }),
              _c("h3", { staticClass: "modal__heading" }, [
                _vm._v("Join TheCharts to " + _vm._s(_vm.message)),
              ]),
              _c(
                "a",
                {
                  staticClass: "btn btn--fullWidth btn--primary",
                  on: { click: _vm.signup },
                },
                [_vm._v("Create Account")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modalContent__footer modalContent__footer--flex" },
            [
              _c("p", [
                _vm._v("Already have an account? "),
                _c("a", { staticClass: "link", on: { click: _vm.login } }, [
                  _vm._v("Log In"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }